import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "./app/hooks";
import BasicChart from "./components/BasicChart";
import DatePicker from "./components/DatePicker";
import { selectDateRange, setDateRange } from "./redux/dateRangeSlice";
import {
  addMeasurements,
  fetchMeasurements,
  selectLoading,
  selectMeasurements,
} from "./redux/measurementSlice";
import { DataPoint, Measurement } from "./types/Measurement";
import { useLocation } from "react-router-dom";
import groupBy from "lodash/groupBy";
import KPICard from "./components/KPICard";
import WoodNotification from "./components/WoodNotification";
import { DateTime } from "luxon";
import { Kake } from "./components/Kake";
import { isEmpty } from "lodash";
import { Loading } from "./components/Loading";

function App() {
  const measurements = useSelector(selectMeasurements);
  const loading = useSelector(selectLoading);
  const dateRange = useSelector(selectDateRange);
  const dispatch = useAppDispatch();

  const filteredData = measurements.filter(
    (data) =>
      new Date(data.timestamp_number).toISOString() >= dateRange.startDate &&
      new Date(data.timestamp_number).toISOString() <= dateRange.endDate
  );

  const location = useLocation();

  useEffect(() => {
    const saveApiKey = async () => {
      const searchParams = new URLSearchParams(location.search);
      const apiKey = searchParams.get("apiKey");
      if (apiKey) {
        localStorage.setItem("apiKey", apiKey);

        try {
          const cache = await caches.open("SAUNAATTORI_CACHE");
          const responseBody = JSON.stringify({
            apiKey: apiKey,
          });
          const response = new Response(responseBody);
          await cache.put("/apiKey", response);
        } catch (error) {
          console.log("save token error", error);
        }
      }
    };
    saveApiKey();
  }, [location.search]);

  useEffect(() => {
    const updateDateRangeInterval = setInterval(() => {
      dispatch(
        setDateRange({
          startDate: DateTime.now()
            .minus({ hours: dateRange.rangeHours })
            .toJSDate()
            .toISOString(),
          endDate: new Date().toISOString(),
          rangeHours: dateRange.rangeHours,
        })
      );
    }, 10 * 1000); // Päivitä loppupäivämäärä joka 10. sekunti

    return () => {
      clearInterval(updateDateRangeInterval);
    };
  }, [dateRange.startDate, dispatch]);

  useEffect(() => {
    const saveToRedux = async () => {
      const apiKey =
        localStorage.getItem("apiKey") ||
        (await getApiKeyFromCacheStorage()) ||
        "";

      dispatch(
        fetchMeasurements({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          apiKey,
        })
      );
    };

    saveToRedux();
  }, [dateRange, dispatch]);

  const getApiKeyFromCacheStorage = async () => {
    try {
      const cache = await caches.open("SAUNAATTORI_CACHE");
      const response = await cache.match("/apiKey");

      if (!response) {
        return null;
      }

      const responseBody = await response.json();
      return responseBody["apiKey"];
    } catch (error) {
      // Gotta catch 'em all
      console.log("getToken error:", { error });
    }
  };

  const grouped = groupBy(filteredData, "timestamp");

  const loadingMeasurements = loading && isEmpty(grouped);

  const dps: DataPoint[] = Object.entries(grouped).map(([_, ms]) => ({
    timestamp: ms[0].timestamp,
    timestamp_number: ms[0].timestamp_number,
    sauna_temperature: ms.find((m) => m.sensor_id === "1")?.temperature,
    outside_temperature: ms.find((m) => m.sensor_id === "0")?.temperature,
    water_temperature: ms.find((m) => m.sensor_id === "2")?.temperature,
    smoke_temperature: ms.find((m) => m.sensor_id === "3")?.temperature,
    rock_temperature: ms.find((m) => m.sensor_id === "4")?.temperature,
  }));

  const kpi = dps[dps.length - 1];
  const previousKpi = dps[dps.length - 2];

  return (
    <div className="w-full h-full space-y-4 px-2">
      <div className="flex flex-col items-center">
        <h1 className="text-white text-center text-xl py-2 font-bold flex flex-col items-end">
          <div className="uppercase italic">Saunaattori</div>
          <div className="text-sm -mt-2 italic flex w-full">
            <div className="flex-grow border-t-[2px] border-white mt-[10px]"></div>

            <span className="ml-1">3000</span>
          </div>
        </h1>
      </div>
      {loadingMeasurements ? (
        <Loading />
      ) : (
        <>
          <WoodNotification dataPoints={dps} />
          <KPICard dataPoint={kpi} previousDataPoint={previousKpi} />
          {dps.length > 0 && <BasicChart measurements={dps} />}
          <div className="!mt-1">
            <DatePicker />
          </div>
        </>
      )}
      <Kake />
    </div>
  );
}

export default App;
