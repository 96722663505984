import { DateTime } from "luxon";
import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DataPoint } from "../types/Measurement";
import * as d3 from "d3";

const temperatureFormatter = (value: number) => Math.round(value * 100) / 100;
const labelFormatter = (value: string) =>
  lineProps.find((line) => line.dataKey === value)?.label || "not found";
const dateFormatter = (value: number) =>
  DateTime.fromMillis(value).toFormat("TT");
const shortDateFormatter = (value: number) =>
  DateTime.fromMillis(value).toFormat("HH.mm");
const dateTimeFormatter = (value: number) =>
  DateTime.fromMillis(value).toFormat("D TT");

export const lineProps = [
  {
    dataKey: "sauna_temperature",
    color: "#A31621",
    unit: "°C",
    label: "Sauna",
  },
  {
    dataKey: "water_temperature",
    color: "#275dad",
    unit: "°C",
    label: "Vesi",
  },
  {
    dataKey: "outside_temperature",
    color: "#fcba03",
    unit: "°C",
    label: "Ulkoilma",
  },
  {
    dataKey: "rock_temperature",
    color: "#702804",
    unit: "°C",
    label: "Kivitila",
  },
  {
    dataKey: "smoke_temperature",
    color: "#787472",
    unit: "°C",
    label: "Savukaasu",
  },
];

const CustomLegend = ({
  latestMeasurement,
  onClick,
  activeLines,
}: {
  latestMeasurement: DataPoint;
  onClick: (line: string) => void;
  activeLines: string[];
}) => {
  return (
    <>
      <div className="flex flex-row justify-center px-1 items-center mt-2">
        {lineProps.map((line) => {
          const { color, unit } = line;

          return (
            // text-[#A31621] text-[#275dad] text-[#92DCE5] text-[#fcba03] text-[#702804] text-[#787472]
            <span
              className={`text-[${color}] flex flex-col justify-center space-y-2 button-style`}
              key={`item-${line.dataKey}`}
            >
              <img
                src={`/img/${line.dataKey}.png`}
                onClick={() => onClick(line.dataKey)}
                className={`${
                  activeLines.includes(line.dataKey)
                    ? "opacity-100"
                    : " opacity-30"
                }`}
              />
            </span>
          );
        })}
      </div>
      {/*  <div className="flex justify-center items-center my-1">
        <div className="text-white text-center text-sm mt-1 text-blue-800 border border-blue-800 px-1">
          Klikkaa kuvakkeita piilottaaksesi/näyttääksesi
        </div>
      </div> */}
    </>
  );
};

function BasicChart({ measurements }: { measurements: DataPoint[] }) {
  const [displayedLines, setDisplayedLines] = useState(
    lineProps.map((prop) => prop.dataKey)
  );

  const toggleLine = (line: string) => {
    const newLines = displayedLines.includes(line)
      ? displayedLines.filter((lin) => lin !== line)
      : [...displayedLines, line];

    setDisplayedLines(newLines);
  };

  /*   const now = new Date();

  const domainToday = d3
    .scaleTime()
    .domain([d3.timeDay.floor(now), d3.timeDay.ceil(now)]);
  const timeFormatter = (tick) => {
    return d3.timeFormat("%H:%M:%S")(new Date(tick));
  };
  const ticks = domainToday.ticks(d3.timeHour.every(1));
  console.log(measurements); */

  return (
    <div>
      <div
        style={{ width: "calc(100% + 20px)", height: 500 }}
        className="-ml-4"
      >
        <ResponsiveContainer>
          <LineChart data={[...measurements]}>
            <CartesianGrid
              strokeDasharray="2"
              fill="#40385c"
              fillOpacity={0.3}
            />
            <XAxis
              dataKey="timestamp_number"
              stroke="white"
              tickFormatter={shortDateFormatter}
              interval="preserveEnd"
              tickMargin={12}
              height={40}
              tickCount={5}
              type="number"
              min={measurements[0].timestamp_number}
              max={measurements[measurements.length - 1].timestamp_number}
              scale="time"
              minTickGap={25}
              domain={["auto", "auto"]}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              stroke="white"
              unit={"°C"}
              domain={[0, "auto"]}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip
              formatter={(value, name) => [
                temperatureFormatter(value as number),
                labelFormatter(name as string),
              ]}
              labelFormatter={dateTimeFormatter}
            />
            {lineProps
              .filter((line) => displayedLines.includes(line.dataKey))
              .map((line) => (
                <Line
                  key={line.dataKey}
                  type="monotone"
                  dataKey={line.dataKey}
                  stroke={line.color}
                  fill={line.color}
                  activeDot={{ r: 8 }}
                  unit={line.unit}
                  dot={false}
                  strokeWidth={3}
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <CustomLegend
        latestMeasurement={measurements[measurements.length - 1]}
        onClick={toggleLine}
        activeLines={displayedLines}
      />
    </div>
  );
}

export default BasicChart;
