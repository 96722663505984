import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterSlice from "../features/counter/counterSlice";
import dateRangeSlice from "../redux/dateRangeSlice";
import measurementSlice from "../redux/measurementSlice";

export const store = configureStore({
  reducer: {
    measurement: measurementSlice,
    counter: counterSlice,
    dateRange: dateRangeSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
