import { createSlice, configureStore } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { RootState } from "../app/store";

const initialState = {
  temperatureData: [],
  dateRange: {
    startDate: DateTime.now().minus({ hours: 1 }).toJSDate().toISOString(),
    endDate: DateTime.now().toJSDate().toISOString(),
    rangeHours: 1,
  },
};

const dateRangeSlice = createSlice({
  name: "temperature",
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange.startDate = new Date(
        action.payload.startDate
      ).toISOString();
      state.dateRange.endDate = new Date(action.payload.endDate).toISOString();
      state.dateRange.rangeHours = action.payload.rangeHours;
    },
  },
});

export const { setDateRange } = dateRangeSlice.actions;

export const selectDateRange = (state: RootState) => state.dateRange.dateRange;

export default dateRangeSlice.reducer;
