import { round } from "lodash";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setDateRange } from "../redux/dateRangeSlice";
import { DataPoint } from "../types/Measurement";
import { lineProps } from "./BasicChart";

const KPICard = ({
  dataPoint,
  previousDataPoint,
}: {
  dataPoint: DataPoint;
  previousDataPoint: DataPoint;
}) => {
  if (!dataPoint) return null;
  // bg-[#A31621] bg-[#275dad] bg-[#fcba03] bg-[#702804] bg-[#787472] border-[#A31621] border-[#275dad] border-[#fcba03] border-[#702804] border-[#787472]
  return (
    <div className="grid grid-cols-2 sm:grid-cols-5 !mt-1 gap-1">
      {lineProps.map((kpi) => {
        const currentValue = dataPoint[
          kpi.dataKey as keyof DataPoint
        ] as number;
        const previousValue = previousDataPoint[
          kpi.dataKey as keyof DataPoint
        ] as number;

        const trendUp = currentValue - previousValue > 0.1;
        const trendDown = currentValue - previousValue < -0.1;

        return (
          <div
            className={`text-white p-2 flex flex-row justify-center items-center bg-[${kpi.color}] border-[${kpi.color}] border-2 space-x-2 bg-opacity-20`}
            key={kpi.dataKey}
          >
            <img src={`/img/${kpi.dataKey}.png`} className="h-9 w-9" />
            <span className="text-xl flex flex-1 flex-row items-center justify-between pr-1">
              <div className="flex flex-col">
                <div className="text-xs opacity-80 mt-[1px]">{kpi.label}</div>
                <div className="font-bold -mt-1 flex flex-row">
                  <div>
                    {round(
                      dataPoint[kpi.dataKey as keyof DataPoint] as number,
                      2
                    )}
                  </div>
                  <div className="ml-1 text-sm mt-auto">°C</div>
                </div>
              </div>
              {trendUp && (
                <img
                  src={`/img/up.png`}
                  className="h-6 w-6 inline-block ml-2"
                />
              )}
              {trendDown && (
                <img
                  src={`/img/down.png`}
                  className="h-6 w-6 inline-block ml-2"
                />
              )}
            </span>
          </div>
        );
      })}
      {DateTime.fromMillis(dataPoint.timestamp_number).diffNow().as("seconds") <
        -120 && (
        <div className="text-white text-center self-center">
          {"Viive > 2min"}
        </div>
      )}
    </div>
  );
};

export default KPICard;
