import { cardStyle } from "./WoodNotification";

export const Loading = () => {
  return (
    <div className="text-center">
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>

      <div className="text-white mt-5">Ladataan...</div>
    </div>
  );
};
