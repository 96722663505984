import { cardStyle } from "./WoodNotification";

export const Kake = () => {
  return (
    <div className="pb-2">
      <a
        className={`flex flex-row items-center space-x-4 justify-center text-lg ${cardStyle}`}
        href="https://qr.mobilepay.fi/box/db6bfcf5-d20e-489d-b78d-7ec550d84842/pay-in"
      >
        <img
          src={`/img/mobile-pay-logo.png`}
          className="w-16 inline-block ml-2 -ml-2"
        />
        <div className="text-white ">Kaken saunarahasto</div>
      </a>
    </div>
  );
};
