import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setDateRange } from "../redux/dateRangeSlice";

const DatePicker = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [hours, setHours] = useState(1);

  const handleDateChange = (e: any) => {
    if (e.target.name === "startDate") {
      setStartDate(new Date(e.target.value));
    } else if (e.target.name === "endDate") {
      setEndDate(new Date(e.target.value));
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setDateRange({ startDate, endDate }));
  };

  const handleQuickSelect = (hours: number) => {
    setHours(hours);
    const newStartDate = new Date();
    newStartDate.setHours(newStartDate.getHours() - hours);
    setStartDate(newStartDate);
    setEndDate(new Date());
    dispatch(
      setDateRange({
        startDate: newStartDate,
        endDate: new Date(),
        rangeHours: hours,
      })
    );
  };

  return (
    <div className="flex flex-col items-center justify-center my-2 px-1 space-y-2">
      <div className="flex items-center mb-2">
        <button
          onClick={() => handleQuickSelect(1)}
          className={`button-style ${hours === 1 ? "!bg-opacity-30" : ""}`}
        >
          1 h
        </button>
        <button
          onClick={() => handleQuickSelect(2)}
          className={`button-style ${hours === 2 ? "!bg-opacity-30" : ""}`}
        >
          2 h
        </button>
        <button
          onClick={() => handleQuickSelect(3)}
          className={`button-style ${hours === 3 ? "!bg-opacity-30" : ""}`}
        >
          3 h
        </button>
        <button
          onClick={() => handleQuickSelect(6)}
          className={`button-style ${hours === 6 ? "!bg-opacity-30" : ""}`}
        >
          6 h
        </button>
        <button
          onClick={() => handleQuickSelect(12)}
          className={`button-style ${hours === 12 ? "!bg-opacity-30" : ""}`}
        >
          12 h
        </button>
        {/*   <button onClick={() => handleQuickSelect(24)} className="button-style">
          24 tuntia
        </button>
        <button onClick={() => handleQuickSelect(168)} className="button-style">
          1 viikko
        </button> */}
      </div>
      {/*       <div className="text-white text-sm">
        Aikaikkunoita tilapäisesti kutistettu..
      </div> */}
      {/* 
      <form onSubmit={handleSubmit} className="flex items-center text-white">
        <label>
          <input
            type="date"
            name="startDate"
            value={startDate.toISOString().substr(0, 10)}
            onChange={handleDateChange}
            className="mx-2 bg-gray-800 text-white border border-gray-700 p-1 rounded-md"
          />
        </label>
        -
        <label>
          <input
            type="date"
            name="endDate"
            value={endDate.toISOString().substr(0, 10)}
            onChange={handleDateChange}
            className="mx-2 bg-gray-800 text-white border border-gray-700 p-1 rounded-md"
          />
        </label>
      </form>
      <button type="submit" className="button-style">
        Päivitä aikaväli
      </button> */}
    </div>
  );
};

export default DatePicker;
