import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../app/store";
import { Measurement } from "../types/Measurement";

export interface MeasurementState {
  measurements: Measurement[];
  loading: boolean;
}

const initialState: MeasurementState = {
  measurements: [],
  loading: false,
};

export const fetchMeasurements = createAsyncThunk(
  "measurement/fetchMeasurements",
  async ({
    startDate,
    endDate,
    apiKey,
  }: {
    startDate: string;
    endDate: string;
    apiKey: string;
  }) => {
    const response = await axios.get(
      `https://api.saunaattori.fi/measurements?apiKey=${apiKey}&startTimestamp=${startDate}&endTimestamp=${endDate}`
    );
    return response.data;
  }
);

export const measurementSlice = createSlice({
  name: "measurement",
  initialState,
  reducers: {
    addMeasurements: (state, action: PayloadAction<Measurement[]>) => {
      state.measurements = [...state.measurements, ...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMeasurements.fulfilled, (state, action) => {
      const measurements = action.payload.map((measurement: any) => ({
        ...measurement,
        timestamp_number: new Date(measurement.timestamp).getTime(),
      }));
      state.measurements = measurements;
      state.loading = false;
    });
    builder.addCase(fetchMeasurements.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchMeasurements.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { addMeasurements } = measurementSlice.actions;

export const selectMeasurements = (state: RootState) =>
  state.measurement.measurements;

export const selectLoading = (state: RootState) => state.measurement.loading;

export default measurementSlice.reducer;
