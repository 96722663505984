import { round } from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDateRange } from "../redux/dateRangeSlice";
import { DataPoint } from "../types/Measurement";
import { lineProps } from "./BasicChart";

export const cardStyle =
  "bg-[#40385c] bg-opacity-50 border-2 border-[#40385c] py-2 text-white";

const getRandom = (items: any[]) =>
  items[Math.floor(Math.random() * items.length)];

interface Message {
  lowerLimit?: number;
  upperLimit?: number;
  value: string;
}

const messagesBank: Message[] = [
  {
    lowerLimit: 140,
    upperLimit: 9999,
    value: "Sauna tulessa!",
  },
  {
    lowerLimit: 120,
    upperLimit: 140,
    value: "Nahka kärventyy!",
  },
  {
    lowerLimit: 120,
    upperLimit: 140,
    value: "Kumisaapaslöylyt",
  },
  {
    lowerLimit: 110,
    upperLimit: 120,
    value: "Kamiina mansikkana!",
  },
  {
    lowerLimit: 100,
    upperLimit: 110,
    value: "LÖYLYÄ!",
  },
  {
    lowerLimit: 100,
    upperLimit: 9999,
    value: "Hylyn kuuma!",
  },
  {
    lowerLimit: 94,
    upperLimit: 100,
    value: "Setämiehet saunomaan!",
  },
  {
    lowerLimit: 60,
    upperLimit: 70,
    value: "Jamo saattaa jo saunoa!",
  },
  {
    lowerLimit: 70,
    upperLimit: 80,
    value: "Nöösipoikien sauna-aika",
  },
  {
    lowerLimit: 40,
    upperLimit: 60,
    value: "Bastu är varm",
  },
  {
    lowerLimit: -30,
    upperLimit: 35,
    value: "Nyt saunaa päälle ja sassiin!",
  },
  {
    lowerLimit: -50,
    upperLimit: 40,
    value: "Laita sauna lämpeen!",
  },
  {
    lowerLimit: 30,
    upperLimit: 80,
    value: "Anna saunan lämmetä!",
  },
  {
    lowerLimit: 80,
    upperLimit: 85,
    value: "Kohta pääsee kylpemään!",
  },
  {
    lowerLimit: 105,
    upperLimit: 115,
    value: "Paatsen korvalöylyt!",
  },
  {
    lowerLimit: 40,
    upperLimit: 80,
    value: "Mummojen kehruulämpö...",
  },
  {
    lowerLimit: 60,
    upperLimit: 100,
    value: "Kohtuu lämmin...",
  },
  {
    lowerLimit: 80,
    upperLimit: 90,
    value: "Ei kai siellä palella tarvi...",
  },
  {
    lowerLimit: 80,
    upperLimit: 90,
    value: "Semmone 4/5",
  },
];

const WoodNotification = ({ dataPoints }: { dataPoints: DataPoint[] }) => {
  const [possibleMessages, setPossibleMessages] = useState<Message[]>([]);
  const [displayedMessage, setDisplayedMessage] = useState<
    Message | undefined
  >();

  if (!dataPoints.length) return null;

  const getTemperatureMessage = (saunaTemperature: number) => {
    const newPossibleMessages = messagesBank.filter(
      (message) =>
        saunaTemperature >= (message?.lowerLimit || 0) &&
        saunaTemperature < (message?.upperLimit || 0)
    );

    return newPossibleMessages;
  };

  const getMessages = (): { messages: Message[]; fill?: boolean } => {
    const saunaTemp = dataPoints[dataPoints.length - 1].sauna_temperature;
    const smokeTemp = dataPoints[dataPoints.length - 1].smoke_temperature;
    const previousSmokeTemp =
      dataPoints[dataPoints.length - 2].smoke_temperature;
    const rockTemp = dataPoints[dataPoints.length - 1].rock_temperature;
    const previousRockTemp = dataPoints[dataPoints.length - 2].rock_temperature;

    if (
      saunaTemp === undefined ||
      smokeTemp === undefined ||
      previousSmokeTemp === undefined ||
      rockTemp === undefined ||
      previousRockTemp === undefined
    ) {
      return { messages: [{ value: "Häikkää häikkää!" }] };
    }

    const fill =
      dataPoints.length > 2 &&
      smokeTemp < previousSmokeTemp &&
      saunaTemp < 105 &&
      smokeTemp > 40;

    const warmingUp =
      saunaTemp < 40 &&
      smokeTemp > 40 &&
      smokeTemp > previousSmokeTemp &&
      rockTemp > previousRockTemp;

    const messages = fill
      ? [
          { value: "Lisää puuta!" },
          { value: "PUITA!" },
          { value: "Klapia pönttöön!" },
          { value: "Pökköö pesään!" },
          { value: "Sehän sammuu!" },
        ]
      : warmingUp
      ? [{ value: "Lämpenee!" }]
      : getTemperatureMessage(saunaTemp);

    return { messages, fill };
  };

  /* useEffect(() => {
    for (let i = -40; i < 200; i++) {
      console.log(i, getTemperatureMessage(i));
    }
  }, []); */

  const { messages, fill } = getMessages();

  if (JSON.stringify(possibleMessages) !== JSON.stringify(messages)) {
    setDisplayedMessage(getRandom(messages));
    setPossibleMessages(messages);
  }

  return (
    <div className={"!mt-0 px-2 flex flex-col items-center " + cardStyle}>
      <div className="flex flex-col ">
        <div className="text-xs opacity-80">Saunan tila</div>
        <div className={`-mt-1 font-bold ${fill ? "text-red-500" : ""}`}>
          {displayedMessage?.value}
        </div>
      </div>
    </div>
  );
};

export default WoodNotification;
